<template>
    <PageLayout>
        <template>

                <div class="d-flex w-100 justify-space-between mb-6">
                    <div class="d-flex align-center">
                        <h1>{{ $t('dashboard') }}</h1>
                    </div>
                    <div class="text-right" v-if="can(['administrator'])">

                        <Btn
                            v-if="$vuetify.breakpoint.smAndUp"
                            @click="dialogAddDashboard=true"
                            color="secondary"
                            :title="$t('dashboard_creation')"
                        >
                            {{ $t('dashboard_creation') }}
                        </Btn>
                        <Teleport to="#app" v-else>
                            <div class="fab__wrapper">
                                <Btn
                                    fab
                                    @click="dialogAddDashboard=true"
                                    :title="$t('dashboard_creation')"
                                    color="secondary"
                                ><v-icon>mdi-plus</v-icon></Btn>
                            </div>
                        </Teleport>
                    </div>
                </div>



                   <draggable v-if="dashboardItems.length > 0"
                              v-model="dashboardItems"
                              :group="{ name: 'dashboard' }"
                              class="row"
                              @change="changeDrag()"
                              :key="componentKey"
                              :disabled="!can(['administrator'])"
                   >

                    <v-row >
                       <template v-for="(item, index ) in dashboardItems" >
                           <v-col cols="12" :sm="item.coll">
                               <v-card>
                                   <div>
                                       <v-app-bar flat color="rgba(0, 0, 0, 0)">
                                           <v-toolbar-title class="text-h6  pl-0">
                                               <div @click="showDashboard(item)" class="cursor-pointer" >{{item.name}}</div>
                                           </v-toolbar-title>
                                           <v-spacer></v-spacer>
                                           <v-menu
                                               bottom
                                               left
                                               v-if="can(['administrator'])"
                                           >
                                               <template v-slot:activator="{ on, attrs }">
                                                   <v-btn
                                                       icon

                                                       v-bind="attrs"
                                                       v-on="on"
                                                   >
                                                       <v-icon small>mdi-dots-vertical</v-icon>
                                                   </v-btn>
                                               </template>
                                               <v-list>
                                                   <v-list-item
                                                       v-for="(menu_item, i) in menuItems"
                                                       :key="i"
                                                       link
                                                   >
                                                       <v-list-item-title @click="actionDashboard(item, menu_item)">{{ menu_item.title }}</v-list-item-title>
                                                   </v-list-item>
                                               </v-list>
                                           </v-menu>
                                       </v-app-bar>
                                   </div>
                                   <v-card-text>
                                      <div v-if="item.widgets.length > 0" style="min-height: 200px; width: 100%">
                                          <chart-display :dashboard="index.toString()" :widgets="item.widgets" @update-widgets="updateParentWidgets" />
                                      </div>
                                   </v-card-text>
                               </v-card>
                           </v-col>
                       </template>
                    </v-row>
                   </draggable>



            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="dialogAddDashboard"
            >
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('dashboard_creation') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeAddDashboard()">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text>
                            <v-row >
                                <v-col cols="12" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="dashboard_name">
                                        <TextInput
                                            id="dashboard_name"
                                            :label="$t('dashboard_name')"
                                            :error="!valid"
                                            :error-messages="errors"
                                            v-model="dashboard_name"
                                            require
                                        ></TextInput>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" >
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="coll">
                                        <SelectInput
                                            clearable
                                            item-text="title"
                                            item-value="value"
                                            hide-details
                                            hide-label
                                            :items="collItems"
                                            v-model="coll"
                                            name="coll"
                                            id="coll"
                                            :hideLabel="false"
                                            :label="$t('coll')"
                                        ></SelectInput>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row >
                                <v-col cols="12" sm="12">
                                    <v-btn :disabled="invalid || loading" :loading="loading" color="primary"
                                           @click="AddDashboard()"
                                           class="infinity_button">
                                        {{ $t('add') }}
                                    </v-btn>
                                    <v-btn
                                        :disabled="loading"
                                        class="ma-1 button_cancel"
                                        plain
                                        @click="closeAddDashboard()"
                                    >
                                        {{ $t('cancel') }}
                                    </v-btn>
                                    <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                                    <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                                       :active="loading" class="mx-2"></v-progress-linear>
                                </v-col>
                            </v-row>

                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-dialog>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="dialogRename"
            >
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('dashboard_editing') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeDialogDashboard()">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="widget_name">
                                        <TextInput
                                            id="dashboard_name2"
                                            :label="$t('dashboard_name')"
                                            :error="!valid"
                                            :error-messages="errors"
                                            v-model="dashboard_name"
                                            require
                                        ></TextInput>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-btn :disabled="invalid || loading" :loading="loading" color="primary"
                                           @click="renameDashboard()"
                                    >
                                        {{ $t('edit') }}
                                    </v-btn>
                                    <v-btn
                                        :disabled="loading"
                                        plain
                                        @click="closeDialogDashboard()"
                                    >
                                        {{ $t('cancel') }}
                                    </v-btn>
                                    <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                                    <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                                </v-col>
                            </v-row>

                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-dialog>

        </template>
        <template v-slot:side v-if="false">
            <v-icon color="primary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
            <v-list class="filter_left mt-5 background_color_transparent">
                <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>

                    <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
    </PageLayout>
</template>
<script>


import {mapGetters} from "vuex";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import PageLayout from "@/components/Leentech/PageLayout.vue";
import Btn from "@/components/Form/Btn.vue";
import TextInput from "@/components/Form/TextInput";
import ChartDisplay from '@/components/ChartDisplay.vue';
import draggable from "vuedraggable";
import SelectInput from "@/components/Form/SelectInput.vue";

export default {
    name: "Dashboard",
    components: {
        PageLayout,
        Btn,
        ValidationObserver,
        ValidationProvider,
        TextInput,
        ChartDisplay,
        draggable,
        SelectInput

    },
    data() {
        return {
            progress: 0,
            loading: false,
            dashboard_name: null,
            dialogAddDashboard: false,
            dashboardItems: [],
            dashboardItems2: [],
            componentKey: 0,
            menuItems: [
                {
                    title: 'Переименовать',
                    action: 'rename'
                },
                {
                    title: this.$t('delete'),
                    action: 'delete'
                },
            ],
            dialogRename: false,
            dashboard_uuid: null,


            system_filters: [
                {'title': 'Избранное', 'count': '0'},
            ],
            Additional: [

            ],
            group1: true,
            group2: true,
            filter_show: true,
            coll: null,
            collItems: [
                { value: 12, title: this.$t('На всю строчку') },
                { value: 6, title: this.$t('1/2') },
                { value: 4, title: this.$t('1/3') },
                { value: 8, title: this.$t('1/1.5') },
            ],
        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
    },
    mounted() {
        this.getDashboards();
    },
    methods: {
        updateParentWidgets(val) {
            this.dashboardItems[val.index]['widgets'] = val.widgets;
        },
        async getDashboards() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/dashboard`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.dashboardItems2 = res.body.data
                    this.loadAllWidgetData();

                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_dashboard'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        showDashboard(item) {
            this.$router.push({
                name: 'dashboard.show',
                params: {
                    uuid: item.uuid
                }
            })
        },
        closeAddDashboard(){
            this.dialogAddDashboard = false;
        },
        async AddDashboard() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()




            if (this.coll) {
                formData.append('coll', this.coll)
            }
            if (this.dashboard_name) {
                formData.append('dashboard_name', this.dashboard_name)
            }
            // Add
            await this.$http
                .post('admin/dashboard', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('dashboard_has_been_added'))
                    this.closeAddDashboard();
                    this.getDashboards();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('dashboard_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async loadAllWidgetData() {
            this.loading = true;
            try {
                for (const [x , item2] of this.dashboardItems2.entries()) {
                    for (const [y, item] of item2.widgets.entries()) {
                        await this.getReportTable(item, x, y);
                    }
                }
            } catch (err) {
                this.$toastr.error(this.$t('failed_to_get_list_widget'));
            } finally {
                this.loading = false;
            }
            this.dashboardItems = this.dashboardItems2;
        },
        async getReportTable(item, x, y) {
            this.loading = true;


            let params = {};

            params.language = this.lang;
            params.table = 'horizontal_bar';

            if(params.table &&  params.table!== ''){

                await this.$http
                    .get(`admin/report_data${item.url}`, {
                        params: item.url,
                    })
                    .then(res => {
                        this.dashboardItems2[x]['widgets'][y].listItems = JSON.stringify(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_list_widget'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }

        },

        actionDashboard(item,menu_item){
            if(menu_item.action === 'delete'){
                this.deleteDashboard(item);
            }
            else if(menu_item.action === 'rename'){
                this.renameDialogDashboard(item);
            }
        },
        async deleteDashboard(item) {
            if (confirm(this.$t('delete_dashboard'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/dashboard/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('dashboard_has_been_deleted'))
                        this.dashboardItems = this.dashboardItems.filter(s_item => s_item.id !== item.id);
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('dashboard_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async saveSortDashboard() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.dashboardItems && this.dashboardItems.length > 0) {
                for (let i in this.dashboardItems) {
                    if (this.dashboardItems[i].id !== 'undefined' && this.dashboardItems[i].id > 0) {
                        formData.append(`sort[${i}]`, this.dashboardItems[i].id)
                    }
                }
            }
            await this.$http
                .post(`admin/dashboard/sort`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('dashboard_sorting_updated'))
                    this.componentKey++;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('dashboard_sorting_not_updated'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        changeDrag() {
            this.saveSortDashboard();
        },
        renameDialogDashboard(item){
            this.dialogRename = true;
            this.dashboard_name = item.name;
            this.dashboard_uuid = item.uuid;
        },
        closeDialogDashboard(){
            this.dialogRename = false;
            this.dashboard_name = null;
            this.dashboard_uuid = null;
        },
        async renameDashboard() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()



            if (this.dashboard_uuid) {
                formData.append('dashboard_uuid', this.dashboard_uuid)
            }

            if (this.dashboard_name) {
                formData.append('dashboard_name', this.dashboard_name)
            }

            // edit
            await this.$http
                .put('admin/dashboard/rename', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('dashboard_has_been_updated'))
                    const dashboard = this.dashboardItems.find(item => item.uuid === this.dashboard_uuid);
                    if (dashboard) {
                        dashboard.name = this.dashboard_name;
                    }
                    this.closeDialogDashboard();

                })
                .catch(err => {
                    this.$toastr.error(this.$t('dashboard_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },


    }
}
</script>
